<template>
  <main class="ma-4">
    <!-- title -->
    <simple-date-header :date="translations.pileDetail + plantName" :goTo="previous"></simple-date-header>

    <!-- pile's data -->
    <div style="background-color:white">
      <v-row class="ma-0 justify-space-between" align="center">
        <v-col cols="2" align="center">
          <div v-if="pile">
            <!-- pie chart if pile type is limited -->
            <PieChart v-if="pileTypeIsLimited" :pile="pile" :pile-type="pileType" />

            <!-- pile's icon (STANDARD type) -->
            <v-icon v-else size="150">$pile</v-icon>
          </div>
        </v-col>

        <v-col cols="9">
          <!-- pile's info -->
          <v-row class="pa-2" v-if="pile">
            <v-col cols="8">
              <v-row>
                <v-col cols="6">
                  <span class="mr-4 pile-info">{{ translations.letter }}</span>{{ pile.letter }}
                </v-col>
                <v-col cols="6">
                  <span class="mr-6 pile-info">{{ translations.typology }}</span>{{ pile.type }}
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <span class="mr-4 pile-info">{{ translations.description }}</span>{{ pile.description }}
                </v-col>
                <v-col cols="6">
                  <span class="mr-4 pile-info">{{ translations.manufacturer }}</span>{{ pileTypeIsLimited || ['SPECIALv2'].includes(pile.type) ? '-' : pile.manufacturer.name }}
                </v-col>
              </v-row>
            </v-col>

            <!-- local unit / project -->
            <v-col cols="4">
              <span class="mr-4 pile-info" :style="pile.pileLocalUnitProjects.length > 1 && pile.type === 'SPECIAL' ? 'display:block' : ''">{{ ['SPECIAL', 'SPECIALv2'].includes(pile.type) ? translations.localUnitProject : translations.localUnit }}</span>
              <span v-if="pile.type !== 'SPECIALv2'">{{ pileLocalUnitProjectName }}</span>
              <span v-else>
                <v-icon @click="openCertificateDialog()">{{ icons.mdiOpenInNew }}</v-icon></span>
            </v-col>
          </v-row>

          <!-- pile's counters -->
          <v-row v-if="pile">
            <v-col cols="4">
              <v-avatar color="main" size="46">
                <v-icon fab color="white" large>{{ icons.mdiEqual }}</v-icon>
              </v-avatar>
              <span class="ml-2" style="font-size:1.2em">{{ `${toFloat(pile.totalTons)}  ${translations.tons}` }}</span>
              <p>{{ translations.remainder.toLowerCase() }}</p>
            </v-col>
            <v-col cols="4">
              <v-avatar color="#ffcc00" size="46">
                <v-icon fab color="white" large>{{ icons.mdiArrowDown }}</v-icon>
              </v-avatar>
              <span class="ml-2" style="font-size:1.2em">{{ `${toFloat(pile.loadedTons)}  ${translations.tons}` }}</span>
              <p>{{ translations.load.toLowerCase() }}</p>
            </v-col>
            <v-col cols="4">
              <v-avatar color="green" size="46">
                <v-icon fab color="white" large>{{ icons.mdiArrowUp }}</v-icon>
              </v-avatar>
              <span class="ml-2" style="font-size:1.2em">{{ toFloat(pile.unloadedTons) + ' ' + translations.tons }}</span>
              <p>{{ translations.unload.toLowerCase() }}</p>
            </v-col>
          </v-row>
        </v-col>

        <!-- pile's certificate and report-->
        <v-col v-if="pile" cols="1">
          <v-row class="pa-3 mt-1 mb-4" v-if="pile.type !== 'SPECIALv2'">
            <div class="d-flex flex-column align-center">
              <!-- direct download of single certificate -->
              <v-btn v-if="pile.pileLocalUnitProjects.length === 1" icon :disabled="disableCertificate" :href="certificateFile" :download="certificateName">
                <v-icon x-large color="red">{{ icons.mdiFilePdfOutline }}</v-icon>
              </v-btn>

              <!-- open pop-up with list of certificates-->
              <v-btn v-else icon :disabled="disableCertificate" @click="openCertificateDialog()">
                <v-icon x-large color="red">{{ icons.mdiFilePdfOutline }}</v-icon>
              </v-btn>
              <p style="font-size:0.8em">{{ translations.download }}</p>
            </div>
          </v-row>
          <v-row class="pa-3">
            <div class="d-flex flex-column align-center">
              <v-btn icon @click="pileMovementsReport()">
                <v-icon x-large color="main">{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
              <p style="font-size:0.8em">{{ translations.print }}</p>
            </div>
          </v-row>
        </v-col>
      </v-row>

      <!-- movements table -->
      <!-- FIXME: pagination and table height -->
      <v-data-table height="45vh" :fixed-header="true" :headers="getHeaders()" :items="pileMovements" :item-class="rowBackground" :items-per-page="options.pageSize" :loading="$apollo.queries.pileMovements.loading" :server-items-length="totalPileMovements" class="elevation-1" :options.sync="options" :footer-props="{ 'disable-items-per-page': true, 'items-per-page-text': 'Elementi per pagina' }">
        <!-- registryId -->
        <template v-slot:[`item.registryId`]="{ item }">
          <div class="el-no-wrap">{{ item.registryId }}</div>
        </template>

        <!-- date -->
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ toHumanDate(item.date) }}</span>
        </template>

        <!-- manufacturer -->
        <template v-slot:[`item.manufacturer`]="{ item }">
          <span>{{ item.manufacturer ? item.manufacturer.name : '-' }}</span>
        </template>

        <!-- local unit/ project -->
        <template v-slot:[`item.localUnit`]="{ item }">
          <span>{{ item.localUnit ? item.localUnit.name : item.project ? item.project.keyCode : '-' }}</span>
        </template>

        <!-- part -->
        <template v-slot:[`item.part`]="{ item }">
          <span>{{ item.part ? item.part.keyCode : '-' }}</span>
        </template>

        <!-- certificate -->
        <template v-slot:[`item.document`]="{ item }">
          <template v-if="item.project || item.localUnit || item.certificate">
            <v-checkbox :disabled="(!item.project && !item.localUnit) || item.certificate" :ripple="false" dense hide-details style="display:inline-block" v-model="item.document" :input-value="item.document" color="main" @change="checkboxUpdated($event, item)"></v-checkbox>
            <v-icon :disabled="!item.project && !item.localUnit" small @click="handleFileUpload(item)">{{ icons.mdiUpload }}</v-icon>
            <input ref="uploader" class="d-none" style="display:inline" type="file" accept="application/pdf" @change="onFileChanged" />
          </template>
        </template>

        <!-- carrier -->
        <template v-slot:[`item.carrier`]="{ item }">
          <span>{{ item.carrier ? item.carrier.name : '-' }}</span>
        </template>

        <!-- load -->
        <template v-slot:[`item.load`]="props">
          <span>{{ toFloat(props.item.load) }}</span>
        </template>

        <!-- unload -->
        <template v-slot:[`item.unload`]="props">
          <span>{{ props.item.type === 'LOAD' ? '-' : toFloat(props.item.unload) }}</span>
        </template>

        <!-- remainder -->
        <template v-slot:[`item.remainder`]="props">
          <span>{{ props.item.type === 'LOAD' ? toFloat(props.item.remainder) + ' (' + toFloat(props.item.unload) + ')' : toFloat(props.item.remainder) }}</span>
        </template>

        <!-- unload operations -->
        <template v-slot:[`item.unloadOperations`]="{ item }">
          <ul class="ma-0 pa-0 el-no-wrap">
            <li v-for="(operation, i) in [item.movedFrom].concat(item.unloadOperations)" v-bind:key="i" style="list-style: none">{{ operation }}</li>
          </ul>
        </template>

        <!-- m3 -->
        <template v-if="pile && ['SPECIAL', 'SPECIALv2'].includes(pile.type)" v-slot:[`item.ddt.m3`]="{ item }">
          <span>{{ item.ddt.m3 ? item.ddt.m3 : '-' }}</span>
        </template>

        <!-- additional information -->
        <template v-if="pile && ['SPECIAL', 'SPECIALv2'].includes(pile.type)" v-slot:[`item.ddt.additionalInfo`]="{ item }">
          <span>{{ item.ddt.additionalInfo ? item.ddt.additionalInfo : '-' }}</span>
        </template>

        <!-- ddt registryId -->
        <template v-if="pile && ['SPECIAL', 'SPECIALv2'].includes(pile.type)" v-slot:[`item.ddt.registryId`]="{ item }">
          <div class="el-no-wrap">
            <span>{{ item.ddt.registryId ? item.ddt.registryId : '-' }}</span>
            &nbsp;
            <v-icon v-if="item.ddt.registryId" small @click="printDDT(item)">
              {{ icons.mdiDownload }}
            </v-icon>
          </div>
        </template>

        <!-- table actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="el-no-wrap">
            <v-icon small class="mr-1" @click="startEditMode(item)">{{ icons.mdiSquareEditOutline }}</v-icon>
            <v-icon small class="mr-1" color="red" @click="deletePileMovement(item)">{{ icons.mdiDelete }}</v-icon>
          </div>
        </template>

        <!-- table footer -->
        <template v-slot:[`footer.prepend`]>
          <div id="center-footer">
            <v-btn fab x-small color="#ffcc00" class="white--text mr-4" :disabled="pile && pileTypeIsLimited && isClosed" @click="saveLoad()">
              <v-icon color="white">{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn fab x-small color="green" class="white--text" :disabled="(pile && pile.type === 'SPECIALv2' ? !hasDocumentOrCertificate : disableCertificate) || isEmpty || (pile && pileTypeIsLimited && !isClosed)" @click="saveUnload()">
              <v-icon color="white">{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <!-- error message -->
      <v-snackbar content-class="text-center" v-model="showErrorAlert" absolute bottom color="error" outlined text timeout="4000">{{ errorMessage }}</v-snackbar>

      <!-- save/update dialog -->
      <v-dialog v-model="showDialog" max-width="500px" @click:outside="endEditMode" @keydown.enter="disabled ? null : save()">
        <v-card class="pa-2">
          <v-card-title>
            <v-row class="ma-2" justify="center">
              <h3 class="main--text">{{ isUpdate ? translations.updateRecord : translations.createRecord }}</h3>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- date picker -->
              <v-row v-if="showDialog">
                <v-col cols="4">
                  <v-subheader>{{ translations.date }}</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-menu v-model="datePicker" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="formattedDate" readonly dense outlined hide-details v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItemRef.date" no-title scrollable @input="datePicker = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <!-- manufacturer -->
              <v-row v-if="pile && pileTypeIsLimited && isLoad">
                <v-col cols="4">
                  <v-subheader>{{ translations.manufacturer }}</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete ref="manufacturer" solo dense hide-details v-if="showDialog" v-model="editedItemRef.manufacturer" :search-input.sync="manufacturerOptions.manufacturerName" :loading="$apollo.queries.manufacturers.loading" :items="manufacturerOptions.list" item-text="name" return-object :disabled="!isUpdate && editedItemRef.type === 'UNLOAD'" clearable>
                    <template v-slot:append-item>
                      <v-row v-if="manufacturerOptions.next && !$apollo.queries.manufacturers.loading" v-intersect="onIntersect" id="manufacturer" class="ma-0" justify="center">
                        <v-progress-circular indeterminate color="main" :size="20"></v-progress-circular>
                      </v-row>
                    </template>

                    <template v-slot:no-data>
                      <v-list-item>
                        {{ translations.noManufacturerFoundWThatName }}
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- local unit -->
              <v-row v-if="pile && pileTypeIsLimited && isLoad">
                <v-col cols="4">
                  <v-subheader>{{ translations.localUnit }}</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete ref="localUnit" solo dense hide-details v-if="showDialog" v-model="editedItemRef.localUnit" :search-input.sync="localUnitOptions.localUnitName" :loading="$apollo.queries.localUnits.loading" :items="localUnitOptions.list" item-text="name" return-object :disabled="!isUpdate && editedItemRef.type === 'UNLOAD'" clearable>
                    <template v-slot:append-item>
                      <v-row v-if="localUnitOptions.next && !$apollo.queries.localUnits.loading" v-intersect="onIntersect" id="localUnit" class="ma-0" justify="center">
                        <v-progress-circular indeterminate color="main" :size="20"></v-progress-circular>
                      </v-row>
                    </template>

                    <template v-slot:no-data>
                      <v-list-item>
                        {{ translations.noLocalUnitFoundWThatName }}
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- local unit / project (SPECIAL) -->
              <v-row v-if="pile && pile.type === 'SPECIAL' && isLoad">
                <v-col cols="4">
                  <v-subheader>{{ translations.localUnitProject }}</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-select solo dense hide-details v-if="showDialog" v-model="selectedLocalUnitProject" :items="availablePileLocalUnitProjects" return-object clearable>
                    <template v-slot:item="data">
                      <span>{{ data.item.localUnit ? `${data.item.localUnit.name}` : `${data.item.project.label}` }}</span>
                    </template>
                    <template v-slot:selection="data">
                      <span>{{ data.item.localUnit ? `${data.item.localUnit.name}` : `${data.item.project.label}` }}</span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <!-- local unit / project (SPECIALv2) -->
              <v-row v-if="pile && pile.type === 'SPECIALv2' && isLoad">
                <v-col cols="4">
                  <v-subheader>{{ translations.localUnitProject }}</v-subheader>
                </v-col>
                <v-col :cols="colNumber">
                  <v-menu v-model="openMenu" :value="openMenu" allow-overflow offset-y max-height="400px" :key="forceUpdate" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        @click.stop="openMenu = true"
                        outlined
                        dense
                        hide-details
                        autocomplete="off"
                        v-model="search"
                        return-object
                        clearable
                        @click:clear="clearSearchText()"
                        @blur="textBlur()"
                        @input="value => inputHandler(value)">
                      </v-text-field>
                    </template>

                    <div style="max-height: 300px; z-index: 2; background-color: #fff" class="scroll-bar">
                      <div class="d-flex align-center justify-space-between px-2 pt-2">
                        <div class="d-flex align-center mr-2">
                          <!-- localUnit menu -->
                          <v-btn @click.prevent=";(activeMenuList = 'localUnits'), (changedProjectList = true)" x-small class="mr-2" :class="[activeMenuList === 'localUnits' ? 'white--text' : '']" :outlined="activeMenuList !== 'localUnits'" color="main">
                            {{ translations.localUnit }}
                          </v-btn>

                          <!-- project menu -->
                          <v-btn @click.prevent=";(activeMenuList = 'projects'), (changedProjectList = true)" x-small :class="[activeMenuList === 'projects' ? 'white--text' : '']" :outlined="activeMenuList !== 'projects'" color="main">
                            {{ translations.projects }}
                          </v-btn>
                        </div>
                        <v-btn x-small icon @click.stop="closeMenuHandler()">
                          <v-icon x-small>{{ icons.mdiClose }}</v-icon>
                        </v-btn>
                      </div>

                      <v-list dense>
                        <!-- local unit list -->
                        <template v-if="activeMenuList === 'localUnits'">
                          <v-subheader>{{ translations.localUnit }}</v-subheader>
                          <v-list-item v-for="localUnit in localUnitOptions.list" :key="localUnit.name" @click="changeLocalUnit(localUnit)">
                            <v-list-item-content>
                              <v-list-item-title>{{ localUnit.name }}</v-list-item-title>
                              <v-list-item-subtitle>{{ localUnit.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!localUnitOptions.loading && localUnitOptions.next">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onIntersectList" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                          <v-list-item v-if="!localUnitOptions.loading && !localUnitOptions.next && localUnitOptions.list.length === 0">
                            <v-list-item-content>
                              <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <!-- project list -->
                        <template v-else-if="activeMenuList === 'projects'">
                          <v-subheader>{{ translations.projects }}</v-subheader>
                          <v-list-item v-for="project in projectOptions.list" @click="changeProject(project)" :key="project.keyCode">
                            <v-list-item-content>
                              <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                              <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!projectOptions.loading && projectOptions.next">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onIntersectList" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                          <v-list-item v-if="!projectOptions.loading && !projectOptions.next && projectOptions.list.length === 0">
                            <v-list-item-content>
                              <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </div>
                  </v-menu>
                </v-col>

                <!-- Part -->
                <v-col v-if="hasParts" cols="3">
                  <v-select v-model="editedItemRef.part" return-object :items="projectParts()" item-text="keyCode" :no-data-text="translations.noPartsAvailable" append-icon="" item-value="id" dense outlined hide-details clearable @click:clear="editedItemRef.part = null">
                    <template v-slot:item="data">
                      <small>{{ `${data.item.keyCode} - ${data.item.description}` }}</small>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <!-- carrier -->
              <v-row v-if="showCarrier">
                <v-col cols="4">
                  <v-subheader>{{ translations.carrier }}</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete ref="carrier" solo dense hide-details v-if="showDialog" v-model="editedItemRef.carrier" :search-input.sync="carrierOptions.manufacturerName" :loading="$apollo.queries.carriers.loading" :items="carrierOptions.list" item-text="name" return-object clearable>
                    <template v-slot:append-item>
                      <v-row v-if="carrierOptions.next && !$apollo.queries.carriers.loading" v-intersect="onIntersect" id="carrier" class="ma-0" justify="center">
                        <v-progress-circular indeterminate color="main" :size="20"></v-progress-circular>
                      </v-row>
                    </template>

                    <template v-slot:no-data>
                      <v-list-item>
                        {{ translations.noCarrierFoundWThatName }}
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- destination pile/category -->
              <v-row v-if="pile && ['SPECIAL', 'SPECIALv2'].includes(pile.type) && isUnload && !isUpdate">
                <v-col cols="4">
                  <v-subheader>{{ translations.destination }}</v-subheader>
                </v-col>

                <v-col cols="8">
                  <v-menu v-model="openDestinationMenu" :value="openDestinationMenu" allow-overflow offset-y max-height="400px" :key="forceUpdateDestination" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        @click.stop="openDestinationMenu = true"
                        dense
                        solo
                        hide-details
                        autocomplete="off"
                        v-model="searchDestination"
                        return-object
                        clearable
                        @click:clear="clearText()"
                        @blur="destinationTextBlur()"
                        @input="value => destinationInputHandler(value)"
                        :append-icon="icons.mdiTriangleSmallDown">
                      </v-text-field>
                    </template>

                    <div style="max-height: 300px; z-index: 3; background-color: #fff" class="scroll-bar">
                      <div class="d-flex align-center justify-space-between px-2 pt-2">
                        <div class="d-flex align-center mr-2">
                          <!-- piles menu -->
                          <v-btn @click.prevent=";(activeDestinationMenuList = 'piles'), (changedList = true)" x-small class="mr-2" :class="[activeDestinationMenuList === 'piles' ? 'white--text' : '']" :outlined="activeDestinationMenuList !== 'piles'" color="main">
                            {{ translations.piles }}
                          </v-btn>

                          <!-- category menu -->
                          <v-btn @click.prevent=";(activeDestinationMenuList = 'categories'), (changedList = true)" x-small :class="[activeDestinationMenuList === 'categories' ? 'white--text' : '']" :outlined="activeDestinationMenuList !== 'categories'" color="main">
                            {{ translations.categories }}
                          </v-btn>
                        </div>
                        <v-btn x-small icon @click.stop="closeDestinationMenuHandler()">
                          <v-icon x-small>{{ icons.mdiClose }}</v-icon>
                        </v-btn>
                      </div>

                      <v-list>
                        <!-- piles list -->
                        <template v-if="activeDestinationMenuList === 'piles'">
                          <v-subheader>{{ translations.piles }}</v-subheader>
                          <v-list-item v-for="pile in pileOptions.list" :key="pile.id" @click="changePile(pile)">
                            <v-list-item-content>
                              <v-list-item-title>{{ pile.letter }}</v-list-item-title>
                              <v-list-item-subtitle>{{ pile.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!pileOptions.loading && pileOptions.next">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onDestinationIntersectList" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                          <v-list-item v-if="!pileOptions.loading && !pileOptions.next && pileOptions.list.length === 0">
                            <v-list-item-content>
                              <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <!-- categories list -->
                        <template v-else-if="activeDestinationMenuList === 'categories'">
                          <v-subheader>{{ translations.categories }}</v-subheader>
                          <v-list-item v-for="category in categoryOptions.list" @click="changeCategory(category)" :key="category.keyCode">
                            <v-list-item-content>
                              <v-list-item-title>{{ category.keyCode }}</v-list-item-title>
                              <v-list-item-subtitle>{{ category.label }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!categoryOptions.loading && categoryOptions.next">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onDestinationIntersectList" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                          <v-list-item v-if="!categoryOptions.loading && !categoryOptions.next && categoryOptions.list.length === 0">
                            <v-list-item-content>
                              <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </div>
                  </v-menu>
                </v-col>
              </v-row>

              <!-- load/unload value -->
              <v-row>
                <v-col cols="4">
                  <v-subheader>{{ isLoad ? translations.load : translations.unload }}</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field ref="load" v-if="showDialog && editedItemRef.type === 'LOAD'" outlined dense hide-details type="number" :rules="[rules.min]" v-model="editedItemRef.load"></v-text-field>
                  <v-text-field ref="unload" v-else-if="showDialog && editedItemRef.type === 'UNLOAD'" outlined dense hide-details type="number" :rules="[rules.min]" v-model="editedItemRef.unload"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <!-- actions -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="main" class="white--text" @click="endEditMode()">{{ translations.close }}</v-btn>
            <v-btn color="main" class="white--text" :disabled="disabled" @click="save()">{{ translations.confirm }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- certificate dialog -->
      <certificate-dialog v-if="showCertificateDialog" :open="showCertificateDialog" :pileLocalUnitProjects="pileLocalUnitProjects" :extended-options="extendedOptions" @close-certificate-dialog="closeCertificateDialog()" @checkbox-updated-event="receiveCheckboxUpdatedEvent" @upload-certificate="receiveUploadCertificateEvent"></certificate-dialog>
    </div>
  </main>
</template>

<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { cloneDeep } from 'lodash'
import {
  mdiFilePdfOutline,
  mdiPrinter,
  mdiEqual,
  mdiArrowDown,
  mdiArrowUp,
  mdiSquareEditOutline,
  mdiDelete,
  mdiPlus,
  mdiDownload,
  mdiTriangleSmallDown,
  mdiOpenInNew,
  mdiUpload
} from '@mdi/js'
import { getMobileDriverShowDDTButton, getPileTypes } from '@/utils/api/config.js'
import debounce from 'lodash/debounce'

// components
import SimpleDateHeader from '@/components/SimpleDateHeader.vue'
import PieChart from '@/components/Charts/PieChart.vue'
import CertificateDialog from '@/components/CertificateDialog.vue'

// queries
import pileQuery from '@/graphql/Pile.gql'
import pileMovementsQuery from '@/graphql/PileMovements.gql'
import manufacturersQuery from '@/graphql/ManufacturersAndCarriers.gql'
import carriersQuery from '@/graphql/Carriers.gql'
import localUnitsQuery from '@/graphql/LocalUnits.gql'
import updatePileMovementsMutation from '@/graphql/UpdatePileMovement.gql'
import deletePileMovementMutation from '@/graphql/DeletePileMovement.gql'
import saveLoadMutation from '@/graphql/SaveLoad.gql'
import saveUnloadMutation from '@/graphql/SaveUnload.gql'
import projectsQuery from '../graphql/Projects.gql'
import pilesQuery from '../graphql/Piles.gql'
import saveCertificate from '../graphql/SaveCertificate.gql'
import updateDocument from '../graphql/updateDocument.gql'
import pileLocalUnitProjects from '../graphql/PileLocalUnitProjects.gql'

import reportsApi from '@/utils/api/reportsApi'

export default {
  name: 'PileMovements',

  components: {
    SimpleDateHeader,
    PieChart,
    CertificateDialog
  },

  async created() {
    this.pileId = this.$route?.query?.pileId
    this.operation = this.$route?.query?.op
    this.currentDate = this.getSimpleDay().substr(0, 10)
    this.categoryOptions.params.dateStr = this.currentDate
    this.projectOptions.params.dateStr = this.currentDate
    this.ddt = getMobileDriverShowDDTButton()
    this.pileTypes = getPileTypes()
  },

  apollo: {
    pile: {
      query: pileQuery,
      variables() {
        return {
          pileId: this.pileId
        }
      },
      fetchPolicy: 'network-only',
      async result({ data }) {
        this.plantId = data.pile.plant.id
        this.pileOptions.params.plantId = this.plantId
      },
      skip() {
        return this.pileId ? false : true
      }
    },

    pileMovements: {
      query: pileMovementsQuery,
      variables() {
        return {
          pileId: this.pileId,
          sort: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? 'DESC' : 'ASC',
          page: this.options.page,
          pageSize: this.options.pageSize
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, totalElements } = data.pileMovements
        this.totalPileMovements = totalElements
        this.pileMovements = this.mapPileMovements(content)
      },
      skip() {
        return this.pileId ? false : true
      }
    },

    manufacturers: {
      query: manufacturersQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.listPageSize,
          sort: 'name',
          order: 'asc',
          manufacturerName: this.manufacturerToSearch
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        if (data) {
          const { content, hasNext } = data.manufacturers
          this.manufacturerOptions.next = hasNext
          this.manufacturerOptions.list = content
        }
      },
      update: data => data.content
    },

    carriers: {
      query: carriersQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.listPageSize,
          sort: 'name',
          order: 'asc',
          carrierName: this.carrierToSearch
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, hasNext } = data.manufacturers
        this.carrierOptions.next = hasNext
        this.carrierOptions.list = content
      },
      update: data => data.content
    },

    localUnits: {
      query: localUnitsQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.listPageSize,
          sort: 'name',
          order: 'asc',
          localUnitName: this.localUnitToSearch
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, hasNext } = data.localUnits
        this.localUnitOptions.next = hasNext
        this.localUnitOptions.list = content
      },
      update: data => data.content
    },

    piles: {
      query: pilesQuery,
      variables() {
        return {
          plantId: this.plantId,
          page: this.page,
          pageSize: this.pageSize
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, hasNext } = data.piles
        this.pileOptions.next = hasNext
        this.pileOptions.list = content.filter(p => p.id !== this.pile.id)
      },
      update: data => data.content,
      skip() {
        return this.plantId ? false : true
      }
    },

    categories: {
      query: projectsQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.pageSize,
          sort: 'keyCode',
          order: 'asc',
          activeStatus: true,
          fetchCategory: true,
          fetchNonCategory: false,
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null,
          filter: this.categoryOptions.params.filter
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, hasNext } = data.projects
        this.categoryOptions.next = hasNext
        this.categoryOptions.list = content
      },
      update: data => data.content,
      skip() {
        return this.currentDate ? false : true
      }
    },

    projects: {
      query: projectsQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.pageSize,
          sort: 'keyCode',
          order: 'asc',
          activeStatus: true,
          fetchCategory: false,
          fetchNonCategory: true,
          dateStr: this.currentDate ? this.formatDate(this.currentDate) : null,
          filter: this.categoryOptions.params.filter
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const { content, hasNext } = data.projects
        this.projectOptions.next = hasNext
        this.projectOptions.list = content
      },
      update: data => data.content,
      skip() {
        return this.currentDate ? false : true
      }
    },

    pileLocalUnitProjects: {
      query: pileLocalUnitProjects,
      variables() {
        return {
          pileId: this.pileId
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        this.pileLocalUnitProjects = this.mapCertificates(data.pileLocalUnitProjects)
      },
      skip() {
        return this.pileId ? false : true
      }
    }
  },

  data: () => ({
    pileId: null,
    plantId: null,
    totalPileMovements: 0,
    pileMovements: [],
    page: 1,
    pageSize: 10,
    options: {
      page: 1,
      pageSize: 10,
      sortBy: ['registryId'],
      sortDesc: [true]
    },
    pileLocalUnitProjects: [],
    editMode: false,
    editedItem: null,
    editedItemRef: null,
    listPageSize: 10,
    showDialog: false,
    isLoad: false,
    isUnload: false,
    isUpdate: false,
    certificateFile: null,
    certificateName: '',
    operation: null,
    showErrorAlert: false,
    errorMessage: '',
    datePicker: false,
    previous: {
      path: '/piles',
      query: {
        plantId: null
      }
    },
    rules: {
      min: value => value > 0
    },
    currentDate: null,
    manufacturerOptions: {
      list: [],
      page: 1,
      next: true,
      query: manufacturersQuery,
      alias: 'manufacturers',
      params: {
        sort: 'name',
        order: 'asc',
        manufacturerName: null
      }
    },
    localUnitOptions: {
      list: [],
      page: 1,
      next: true,
      query: localUnitsQuery,
      alias: 'localUnits',
      params: {
        sort: 'name',
        order: 'asc',
        localUnitName: null
      }
    },
    carrierOptions: {
      list: [],
      page: 1,
      next: true,
      query: carriersQuery,
      alias: 'manufacturers',
      params: {
        sort: 'name',
        order: 'asc',
        manufacturerName: null
      }
    },
    pileOptions: {
      list: [],
      page: 1,
      next: true,
      query: pilesQuery,
      alias: 'piles',
      loading: false,
      params: {
        plantId: null,
        filter: null
      }
    },
    categoryOptions: {
      list: [],
      page: 1,
      next: true,
      query: projectsQuery,
      alias: 'projects',
      loading: false,
      params: {
        sort: 'keyCode',
        order: 'asc',
        activeStatus: true,
        fetchCategory: true,
        fetchNonCategory: false,
        dateStr: null,
        filter: null
      }
    },
    projectOptions: {
      list: [],
      page: 1,
      next: true,
      query: projectsQuery,
      alias: 'projects',
      loading: false,
      params: {
        sort: 'keyCode',
        order: 'asc',
        activeStatus: true,
        fetchCategory: false,
        fetchNonCategory: true,
        dateStr: null,
        filter: null
      }
    },
    ddt: false,
    availablePileLocalUnitProjects: [],
    selectedLocalUnitProject: null,
    openDestinationMenu: false,
    forceUpdateDestination: 0,
    searchDestination: null,
    activeDestinationMenuList: 'piles',
    changedList: false,
    showCertificateDialog: false,
    isSelecting: false,
    extendedOptions: false,
    openMenu: false,
    forceUpdate: 0,
    search: null,
    activeMenuList: 'localUnits',
    changedProjectList: false,
    selectedPileLocalUnitProject: null,
    pileTypes: []
  }),

  mixins: [MomentMixin],

  methods: {
    toHumanDate(date) {
      return this.momentDate(date, 'DD/MM/YYYY')
    },

    toFloat(value) {
      return parseFloat(value).toFixed(2)
    },

    pileMovementsReport() {
      reportsApi.pileMovementsReport(this.pile.id, null, this.pile.type)
    },

    resetFields() {
      this.editMode = false
      this.isUpdate = false
      this.isLoad = false
      this.isUnload = false
      this.editedItem = null
      this.editedItemRef = null
      this.showDialog = false
      this.datePicker = false
      this.search = null
      this.searchDestination = null
      this.selectedLocalUnitProject = null
      this.availablePileLocalUnitProjects = []
    },

    async updatePileMovement(pileMovement) {
      try {
        await this.$apollo.mutate({
          mutation: updatePileMovementsMutation,
          variables: {
            pileMovementDTO: {
              id: pileMovement.id,
              carrier: pileMovement.carrier ? { id: pileMovement.carrier.id } : null,
              manufacturer: pileMovement.manufacturer ? { id: pileMovement.manufacturer.id } : null,
              localUnit: pileMovement.localUnit ? { id: pileMovement.localUnit.id } : null,
              project: pileMovement.project ? { id: pileMovement.project.id } : null,
              part: pileMovement.part ? { id: pileMovement.part.id } : null,
              dateStr: this.formatDate(pileMovement.date),
              value: pileMovement.type === 'LOAD' ? pileMovement.load : pileMovement.unload
            }
          }
        })

        this.$apollo.queries.pileMovements.refetch()
        this.$apollo.queries.pile.refetch()
        this.$apollo.queries.pileLocalUnitProjects.refetch()
        this.resetFields()
      } catch (error) {
        this.endEditMode()
        console.error(error)
        this.errorMessage = error.message.includes('negative') || error.message.includes('insufficient') ? this.translations.insufficientTonsError : this.translations.updateError
        this.showErrorAlert = true
      }
    },

    startEditMode(pileMovement) {
      this.editMode = true
      this.isUpdate = true
      this.isLoad = pileMovement.type === 'LOAD'
      this.isUnload = pileMovement.type === 'UNLOAD'
      this.availablePileLocalUnitProjects = this.setAvailablePileLocalUnitProjects()
      this.editedItem = cloneDeep(pileMovement)
      this.editedItemRef = pileMovement
      this.editedItemRef.dateStr = this.toHumanDate(this.editedItem.date)
      this.editedItemRef.date = this.formatDate(this.editedItem.date)
      this.selectedLocalUnitProject = this.pile && ['SPECIAL', 'SPECIALv2'].includes(this.pile.type) ? this.getSelectedLocalUnitProject(pileMovement) : null
      this.showDialog = true

      // select current manufacturer
      if (this.editedItem.manufacturer && this.editedItem.type === 'LOAD' && this.pileTypeIsLimited) {
        setTimeout(() => {
          this.$refs.manufacturer.focus()
          this.manufacturerToSearch = this.editedItem.manufacturer.name
        }, 200)
      }

      // select current localUnit
      if (this.editedItem.localUnit && this.editedItem.type === 'LOAD' && this.pileTypeIsLimited) {
        setTimeout(() => {
          this.$refs.localUnit.focus()
          this.localUnitToSearch = this.editedItem.localUnit.name
        }, 200)
      }

      // select current carrier
      if (this.showCarrier && this.editedItem.carrier) {
        setTimeout(() => {
          this.$refs.carrier.focus()
          this.carrierToSearch = this.editedItem.carrier.name
        }, 200)
      }

      // focus on value field
      setTimeout(() => {
        this.$refs[this.editedItem.type.toLowerCase()].focus()
      }, 200)
    },

    setAvailablePileLocalUnitProjects() {
      if (this.isLoad) {
        return this.pileLocalUnitProjects
      } else {
        let items = cloneDeep(this.pileLocalUnitProjects)
        return items.filter(i => i.certificate)
      }
    },

    getSelectedLocalUnitProject(pileMovement) {
      for (let item of this.pileLocalUnitProjects) {
        if (pileMovement.localUnit && item.localUnit && item.localUnit.id === pileMovement.localUnit.id) {
          return item
        }
        if (pileMovement.project && item.project && item.project.id === pileMovement.project.id) {
          if (pileMovement.part) {
            if (item.part && item.part.id === pileMovement.part.id) {
              return item
            }
          } else {
            return item
          }
        }
      }
      return null
    },

    endEditMode() {
      // reset orignal item
      let index = this.pileMovements.indexOf(this.editedItemRef)
      if (index !== -1) {
        this.pileMovements.splice(index, 1, this.editedItem)
      }

      this.resetFields()
    },

    async deletePileMovement(pileMovement) {
      try {
        // delete record
        await this.$apollo.mutate({
          mutation: deletePileMovementMutation,
          variables: { pileMovementId: pileMovement.id }
        })

        this.$apollo.queries.pileMovements.refetch()
        this.$apollo.queries.pile.refetch()
        this.$apollo.queries.pileLocalUnitProjects.refetch()
      } catch (error) {
        console.error(error)
        this.errorMessage = error.message.includes('negative') ? this.translations.insufficientTonsError : this.translations.deleteError
        this.showErrorAlert = true
      }
    },

    saveLoad() {
      this.showDialog = true
      this.isLoad = true
      this.editedItemRef = this.buildPileMovement('LOAD')
      this.availablePileLocalUnitProjects = this.setAvailablePileLocalUnitProjects()
    },

    saveUnload() {
      this.showDialog = true
      this.isUnload = true
      this.editedItemRef = this.buildPileMovement('UNLOAD')
      this.availablePileLocalUnitProjects = this.setAvailablePileLocalUnitProjects()
    },

    async save() {
      // update record
      if (this.isUpdate) {
        return await this.updatePileMovement(this.editedItemRef)
      }

      // save new record
      try {
        await this.$apollo.mutate({
          mutation: this.isLoad ? saveLoadMutation : saveUnloadMutation,
          variables: this.getSaveRequestDTO()
        })
        this.$apollo.queries.pileMovements.refetch()
        this.$apollo.queries.pile.refetch()
        this.$apollo.queries.pileLocalUnitProjects.refetch()
      } catch (error) {
        console.error(error)
        this.errorMessage = error.message.includes('insufficient') ? this.translations.insufficientTonsError : error.message.includes('exceeded') ? this.translations.exceededTonsError : this.translations.saveError
        this.showErrorAlert = true
      }

      this.resetFields()
    },

    mapCertificates(pileLocalUnitProjects) {
      if (!pileLocalUnitProjects) {
        return []
      }
      for (let plp of pileLocalUnitProjects) {
        if (plp.certificate) {
          if (pileLocalUnitProjects.length === 1 && this.pile.type != 'SPECIALv2') {
            this.certificateFile = `data:application/pdf;base64,${plp.certificateDto.certificate}`
            this.certificateName = plp.certificateDto.filename
          } else {
            // FIXME: use certificateDto fields directly
            plp['certificateBase64'] = `data:application/pdf;base64,${plp.certificateDto.certificate}`
            plp['certificateName'] = plp.certificateDto.filename
          }
        }
      }
      return pileLocalUnitProjects
    },

    getSaveRequestDTO() {
      if (this.isLoad) {
        return {
          loadDTO: {
            carrier: this.editedItemRef?.carrier ? { id: this.editedItemRef.carrier.id } : null,
            localUnit: this.editedItemRef?.localUnit ? { id: this.editedItemRef?.localUnit?.id } : null,
            project: this.editedItemRef?.project ? { id: this.editedItemRef.project.id } : null,
            part: this.editedItemRef?.part ? { id: this.editedItemRef.part.id } : null,
            manufacturer: this.editedItemRef?.manufacturer ? { id: this.editedItemRef.manufacturer.id } : null,
            pile: { id: this.pile?.id },
            dateStr: this.formatDate(this.editedItemRef.date),
            value: this.editedItemRef?.load
          }
        }
      }
      return {
        unloadDTO: {
          carrier: this.editedItemRef?.carrier ? { id: this.editedItemRef.carrier.id } : null,
          localUnit: this.editedItemRef?.localUnit ? { id: this.editedItemRef?.localUnit?.id } : null,
          project: this.editedItemRef?.project ? { id: this.editedItemRef.project.id } : null,
          part: this.editedItemRef?.part ? { id: this.editedItemRef.part.id } : null,
          pile: { id: this.pile?.id },
          dateStr: this.formatDate(this.editedItemRef.date),
          value: this.editedItemRef?.unload,
          destinationPile: this.editedItemRef?.destinationPile ? { id: this.editedItemRef.destinationPile.id } : null,
          destinationCategory: this.editedItemRef?.destinationCategory ? { id: this.editedItemRef.destinationCategory.id } : null
        }
      }
    },

    buildPileMovement(type) {
      return {
        manufacturer: type === 'UNLOAD' || !this.pileTypeIsLimited ? this.pile?.manufacturer : null,
        localUnit: this.pile?.type === 'STANDARD' ? this.pile?.pileLocalUnitProjects[0].localUnit : null,
        project: null,
        part: null,
        carrier: null,
        pile: { id: this.pile?.id },
        load: 0,
        unload: 0,
        dateStr: this.toHumanDate(this.getSimpleDay()),
        date: this.formatDate(this.getSimpleDay()),
        type: type,
        destinationPile: null,
        destinationCategory: null
      }
    },

    rowBackground(item) {
      return item.type === 'UNLOAD' ? 'unload-row' : item.unloaded ? 'load-row' : ''
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchMore(entries[0].target.id)
      }
    },

    getHeaders() {
      let headers = [
        { text: translations.registryId, value: 'registryId' },
        { text: translations.date, value: 'date' },
        { text: translations.manufacturer, value: 'manufacturer', sortable: false },
        { text: translations.localUnit, value: 'localUnit', sortable: false },
        { text: translations.carrier, value: 'carrier', sortable: false },
        { text: translations.load, value: 'load', sortable: false },
        { text: translations.unload, value: 'unload', sortable: false },
        { text: translations.remainderWithUnload, value: 'remainder', sortable: false },
        { text: translations.unloadedFrom, value: 'unloadOperations', sortable: false },
        { value: 'actions', sortable: false }
      ]

      if (this.pile && ['SPECIAL', 'SPECIALv2'].includes(this.pile.type)) {
        // part column
        let index = headers.indexOf(headers.find(h => h.value === 'localUnit'))
        if (index !== -1) {
          headers.splice(index + 1, 0, { text: translations.part, value: 'part', sortable: false })
        }

        // certificate columns
        if (this.pile.type === 'SPECIALv2') {
          // certificate checkbox
          index = headers.indexOf(headers.find(h => h.value === 'part'))
          if (index !== -1) {
            headers.splice(index + 1, 0, { text: translations.certificateColumn, value: 'document', sortable: false })
          }
        }

        // update labels
        index = headers.indexOf(headers.find(h => h.value === 'localUnit'))
        if (index !== -1) {
          headers[index].text = translations.localUnitProject
        }

        // destination column
        index = headers.indexOf(headers.find(h => h.value === 'remainder'))
        if (index !== -1) {
          headers.splice(index + 1, 0, { text: translations.destination, value: 'destination', sortable: false })
        }

        // add ddt columns
        if (this.ddt) {
          index = headers.indexOf(headers.find(h => h.value === 'unloadOperations'))
          if (index !== -1) {
            headers.splice(index + 1, 0, {
              text: translations.m3,
              value: 'ddt.m3',
              sortable: false
            }, { text: translations.additionalInfo, value: 'ddt.additionalInfo', sortable: false })
          }

          index = headers.indexOf(headers.find(h => h.value === 'ddt.additionalInfo'))
          if (index !== -1) {
            headers.splice(index + 1, 0, { text: translations.ddtRegistryId, value: 'ddt.registryId', sortable: false })
          }
        }
      }
      return headers
    },

    printDDT(item) {
      reportsApi.ddt(item.ddt.id)
    },

    clearText() {
      this.searchDestination = null
      this.editedItemRef.destinationCategory = null
      this.editedItemRef.destinationPile = null
    },

    destinationTextBlur() {
      setTimeout(() => {
        if (this.changedList) {
          this.changedList = false
          return
        } else {
          this.searchDestination = this.getSelectedDestination()
          this.openDestinationMenu = false
          this.refetchDestinations()
        }
      }, 400)
    },

    getSelectedDestination() {
      if (this.editedItemRef) {
        if (this.editedItemRef.destinationPile) {
          return this.editedItemRef.destinationPile.letter
        }
        if (this.editedItemRef.destinationCategory) {
          return this.editedItemRef.destinationCategory.label
        }
      }
      return ''
    },

    destinationInputHandler(value) {
      this.searchDestination = value
      this.pileOptions.params.filter = value
      this.categoryOptions.params.filter = value
      this.refetchDestinations()
    },

    closeDestinationMenuHandler() {
      this.searchDestination = this.getSelectedDestination()
      this.pileOptions.params.filter = this.search
      this.categoryOptions.params.filter = this.search
      this.openDestinationMenu = false
      this.refetchDestinations()
    },

    changePile(value) {
      this.editedItemRef.destinationPile = value
      this.editedItemRef.destinationCategory = null
      this.search = value.letter
      this.openMenu = false
    },

    changeCategory(value) {
      this.editedItemRef.destinationCategory = value
      this.editedItemRef.destinationPile = null
      this.search = value.keyCode
      this.openMenu = false
    },

    onDestinationIntersectList(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.activeDestinationMenuList === 'piles') {
          this.fetchMore('pile')
        } else {
          this.fetchMore('category')
        }
      }
    },

    refetchDestinations: debounce(function() {
      this.fetchMore('pile', true)
      this.fetchMore('category', true)
    }, 850),

    async fetchMore(entity, reset = false) {
      // get entity's query options
      let options = this[entity + 'Options']

      if (!reset) {
        options.page++
      } else {
        options.page = 1
        options.next = true
        options.list = []
      }

      const res = await this.$apollo.query({
        query: options.query,
        variables: {
          page: options.page,
          pageSize: this.pageSize,
          ...options.params
        },
        fetchPolicy: 'network-only'
      })
      options.next = res.data[options.alias].hasNext
      options.list.push(...res.data[options.alias].content)

      // exclude current pile from list
      if (entity === 'pile') {
        options.list = options.list.filter(i => i.id !== this.pile.id)
      }
    },

    openCertificateDialog() {
      this.showCertificateDialog = true
    },

    closeCertificateDialog() {
      this.showCertificateDialog = false
    },

    mapPileMovements(pileMovements) {
      return pileMovements.map(pm => {
        pm.destination = pm.destinationPile ? pm.destinationPile.letter : pm.destinationCategory ? pm.destinationCategory.keyCode : '-'
        pm.ddt = pm.ddt ? pm.ddt : { id: null, m3: null, additionalInfo: null }

        if (this.pile && this.pile.type === 'SPECIALv2') {
          let pileLocalUnitProject = this.getPileLocalUnitProject(pm)
          pm.pileLocalUnitProjectId = pileLocalUnitProject ? pileLocalUnitProject.id : null
          pm.document = pileLocalUnitProject ? pileLocalUnitProject.document : null
          pm.certificate = pileLocalUnitProject ? pileLocalUnitProject.certificate : null
        }
        return pm
      })
    },

    getPileLocalUnitProject(pileMovement) {
      let res = this.pileLocalUnitProjects.filter(i => (i.localUnit ? i.localUnit.id === pileMovement.localUnit?.id : i.project ? i.project.id === pileMovement.project?.id && i.part?.id === pileMovement.part?.id : false))

      return res.length ? res[0] : null
    },

    handleFileUpload(pileMovement) {
      this.isSelecting = true
      this.selectedPileLocalUnitProject = pileMovement.isPileLocalUnitProject ? pileMovement : this.getPileLocalUnitProject(pileMovement)

      // after obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      // trigger click on the FileInput
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      if (this.selectedPileLocalUnitProject) {
        this.selectedPileLocalUnitProject.certificateData = e.target.files[0]
        const reader = new FileReader()

        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
          this.selectedPileLocalUnitProject.certificateBase64 = reader.result.substr(reader.result.indexOf(',') + 1)

          this.$apollo
            .mutate({
              mutation: saveCertificate,
              variables: {
                pileLocalUnitProjectId: this.selectedPileLocalUnitProject.id,
                certificateBase64: this.selectedPileLocalUnitProject.certificateBase64
              }
            })
            .then(async () => {
              this.selectedPileLocalUnitProject = null
              await this.$apollo.queries.pileLocalUnitProjects.refetch()
              this.$apollo.queries.pileMovements.refetch()
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },

    clearSearchText() {
      this.search = null
      this.editedItemRef.project = null
      this.editedItemRef.localUnit = null
    },

    textBlur() {
      setTimeout(() => {
        if (this.changedProjectList) {
          this.changedProjectList = false
          return
        } else {
          this.search = this.getSelectedValue()
          this.openMenu = false
          this.refetch()
        }
      }, 400)
    },

    getSelectedValue() {
      if (this.editedItemRef?.localUnit?.name) {
        return this.editedItemRef?.localUnit?.name
      } else if (this.editedItemRef?.project?.keyCode) {
        return this.editedItemRef.project.keyCode
      } else return ''
    },

    inputHandler(value) {
      this.search = value
      this.localUnitOptions.params.localUnitName = value
      this.refetch()
    },

    closeMenuHandler() {
      this.search = this.getSelectedValue()
      this.localUnitOptions.params.localUnitName = this.search
      this.openMenu = false
      this.refetch()
    },

    changeProject(value) {
      this.editedItemRef.project = value?.project ? value.project : value
      this.editedItemRef.part = null
      this.editedItemRef.localUnit = null
      this.search = value.keyCode
      this.openMenu = false
    },

    changeLocalUnit(value) {
      this.editedItemRef.localUnit = value?.localUnit ? value.localUnit : value
      this.editedItemRef.project = null
      this.editedItemRef.part = null
      this.search = value.name
      this.openMenu = false
    },

    projectParts() {
      return this.editedItemRef?.project?.parts
    },

    onIntersectList(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.activeMenuList === 'localUnits') {
          this.fetchMore('localUnit')
        } else {
          this.fetchMore('project')
        }
      }
    },

    refetch: debounce(function() {
      this.fetchMore('localUnit', true)
      this.fetchMore('project', true)
    }, 850),

    async checkboxUpdated(value, item) {
      this.$nextTick(async () => {
        try {
          await this.$apollo.mutate({
            mutation: updateDocument,
            variables: {
              pileLocalUnitProjectId: item.pileLocalUnitProjectId,
              document: value
            }
          })

          // update all pileMovements that share the same PileLocalUnitProject
          await this.$apollo.queries.pileLocalUnitProjects.refetch()
          this.$apollo.queries.pileMovements.refetch()
        } catch (error) {
          console.error(error)
        }
      })
    },

    receiveCheckboxUpdatedEvent(data) {
      this.checkboxUpdated(data.value, data.item, true)
    },

    receiveUploadCertificateEvent(data) {
      data.isPileLocalUnitProject = true
      this.handleFileUpload(data)
    }
  },

  computed: {
    translations: () => translations,

    plantName() {
      return this.pile?.plant?.name ? this.pile.plant.name : ''
    },

    disableCertificate() {
      return !this.pileLocalUnitProjects.some(i => i.certificate)
    },

    pileType() {
      if (this.pileTypes.length === 0) return {}

      return this.pileTypes.find(type => type.type === this.pile.type)
    },

    pileTypeIsLimited() {
      if (!this.pileType?.type) return false

      return this.pileType.limited
    },

    icons: () => ({
      mdiFilePdfOutline,
      mdiPrinter,
      mdiEqual,
      mdiArrowDown,
      mdiArrowUp,
      mdiSquareEditOutline,
      mdiDelete,
      mdiPlus,
      mdiDownload,
      mdiTriangleSmallDown,
      mdiOpenInNew,
      mdiUpload
    }),

    isEmpty() {
      return this.pile?.totalTons === 0
    },

    isClosed() {
      return this.pile?.closed
    },

    formattedDate() {
      return this.editedItemRef ? this.toHumanDate(this.editedItemRef.date) : ''
    },

    disabled() {
      if (this.editedItemRef) {
        let pm = this.editedItemRef

        if (pm.type === 'LOAD') {
          if (this.pile.type === 'STANDARD') {
            return !pm.date || !pm.carrier?.id || pm.load <= 0
          } else if (this.pileTypeIsLimited) {
            return !pm.date || !pm.manufacturer?.id || !pm.localUnit?.id || !pm.carrier?.id || pm.load <= 0
          } else if (this.pile.type === 'SPECIAL') {
            return !pm.date || !pm.manufacturer?.id || (pm.ddt && pm.ddt.m3 ? false : !pm.carrier?.id) || pm.load <= 0 || (!pm.localUnit?.id && !pm.project?.id)
          } else {
            return !pm.date || (pm.ddt && pm.ddt.m3 ? false : !pm.carrier?.id) || pm.load <= 0 || (!pm.localUnit?.id && !pm.project?.id)
          }
        } else if (pm.type === 'UNLOAD') {
          if (!['SPECIAL', 'SPECIALv2'].includes(this.pile.type)) {
            return !pm.date || !pm.carrier?.id || pm.unload <= 0
          } else {
            return !pm.date || (!pm.destinationPile?.id && !pm.destinationCategory?.id) || pm.unload <= 0
          }
        }
      }

      return true
    },

    pileLocalUnitProjectName() {
      let name = ''
      if (!this.pileTypeIsLimited) {
        for (let pileLocalUnitProject of this.pileLocalUnitProjects) {
          if (pileLocalUnitProject.localUnit) {
            name = name + (name ? ' - ' : '') + pileLocalUnitProject.localUnit.name
          } else if (pileLocalUnitProject.project) {
            name = name + (name ? ' - ' : '') + (pileLocalUnitProject.project.keyCode + ' ' + pileLocalUnitProject.project.label)
          }
        }
      } else {
        name = '-'
      }
      return name
    },

    showCarrier() {
      return this.editedItemRef?.ddt?.m3 || (this.pile && this.pile.type === 'SPECIAL' && this.isUnload) ? false : true
    },

    hasDocumentOrCertificate() {
      return this.pileLocalUnitProjects.length === 0 || this.pileLocalUnitProjects.some(i => i.document || i.certificate)
    },

    colNumber() {
      if (this.editedItemRef?.project?.parts?.length) {
        return '5'
      }
      return '8'
    },

    hasParts() {
      return this.editedItemRef?.project?.parts?.length ? true : false
    },

    localUnitValue() {
      return this.editedItemRef?.localUnit
    },

    projectValue() {
      return this.editedItemRef?.project
    }
  },

  watch: {
    pile() {
      // show movement creation dialog if redirected from pile view
      if (this.operation) {
        if (this.operation === 'load') {
          this.saveLoad()
        } else if (this.operation === 'unload') {
          this.saveUnload()
        }
        this.operation = null
      }

      // set route's query param
      if (!this.previous.query.plantId) {
        this.previous.query.plantId = this.pile.plant.id
      }

      this.extendedOptions = this.pile && this.pile.type === 'SPECIALv2'
    },

    selectedLocalUnitProject() {
      if (this.editedItemRef) {
        if (this.selectedLocalUnitProject) {
          this.editedItemRef.localUnit = this.selectedLocalUnitProject.localUnit ? this.selectedLocalUnitProject.localUnit : null
          this.editedItemRef.project = this.selectedLocalUnitProject.project ? this.selectedLocalUnitProject.project : null
          this.editedItemRef.part = this.selectedLocalUnitProject.part ? this.selectedLocalUnitProject.part : null
        } else {
          this.editedItemRef.localUnit = null
          this.editedItemRef.project = null
          this.editedItemRef.part = null
        }
      }
    },

    destinationSearch() {
      this.categoryOptions.params.filter = this.destinationSearch
      this.pileOptions.params.filter = this.destinationSearch
    },

    search() {
      this.localUnitOptions.params.localUnitName = this.search
      this.projectOptions.params.filter = this.search
    },

    localUnitValue() {
      this.search = this.getSelectedValue()
    },

    projectValue() {
      this.search = this.getSelectedValue()
    }
  }
}
</script>

<style>
.pile-info {
  color: #005792;
}

#center-footer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.unload-row {
  background-color: #d1ffd6 !important;
}

.load-row {
  background-color: #fbeeb9 !important;
}

.el-no-wrap {
  white-space: nowrap;
}
</style>
